"use strict";

/**
 * Skip navigation to  #main-content popup for accessibility
 */
$(function () {
  var srNav = $('.sr-nav');
  srNav.on('focus mouseenter', function (e) {
    $(this).attr('tabindex', -1).addClass('skip-links-popup fadeIn active').removeClass('sr-only sr-only-focusable');
    ONNIT.cycle_header_messages.stop();
    $('.skip-to-main-content').focus();
  });
  $(window).on('keyup', function (e) {
    var focus = $(document.activeElement);
    if (e.keyCode === 27 && (focus.is('.sr-nav') || srNav.has(focus).length)) {
      removeNavPopup(srNav);
    }
  });
  $("#main-content").attr('tabindex', '-1');
  $(".skip-to-main-content").on('click', function (e) {
    removeNavPopup(srNav);
    setTimeout(function () {
      $("#main-content").focus();
    }, 10);
  });
  srNav.on('focusout mouseout mouseleave', function (e) {
    setTimeout(function () {
      var focus = $(document.activeElement);
      if (!focus.is('.sr-nav') && !srNav.has(focus).length) {
        removeNavPopup(srNav);
      }
    }, 0);
  });
  /* Remove broken implementation of aria tags */

  $(".slick-slide").removeAttr('aria-describedby');
  $(".slick-dots").removeAttr('role');
  $(".slick-dots li").removeAttr('role').removeAttr('aria-controls').removeAttr('aria-selected');
});
/***
 * Main Navigation Web Accessibility Functionality
 */

$(function () {
  var tabFocusClass = 'tab-focus';
  var openClass = 'is-open';
  var navSelector = '#academy-nav .site-nav-primary-item .site-nav-primary-anchor';
  var menuElemOpen = null;
  var prevEvent = null;
  var primaryNav = $('nav#academy-nav');
  var utilNav = $('.site-util-primary-list');
  /**
   * Listener to remove focus from main nav. Used to remove child tabFocusClass from nav items
   */

  $(document).on('focusout mouseleave', '#academy-nav.focus', function (e) {
    var active = null; // Hack to get newly active element

    setTimeout(function () {
      active = $(document.activeElement).parents('#academy-nav');
      if (active.length <= 0) {
        primaryNav.trigger('focusRemoved');
      }
    }, 1);
  });
  $(document).on('focusout mouseout', '.menu-item.site-nav-primary-item', function (e) {
    primaryNav.trigger('focusRemoved');
  });
  /**
   * Remove focus from any open menus after tabbing through main nav
   */

  $(document).on('focusRemoved', '#academy-nav', function (e) {
    $(this).removeClass('focus').find('li.site-nav-primary-item').removeClass(tabFocusClass + ' ' + openClass).find('a.site-nav-primary-anchor').attr('aria-expanded', false);
  });
  /**
   * Display auth image of product when tabbing through subnav
   */

  $('.site-nav-secondary-anchor').on('focus', function (e) {
    var target = $(e.target);
    $(target).trigger('mouseenter');
    ONNIT.nav.show_product(null, target);
    primaryNav.addClass('focus');
  });
  /**
   * Display subnav when tabbing through #primary-nav on Desktop
   */

  $(navSelector).on('mouseover focus', function (e) {
    $('li.site-nav-primary-item.has-secondary').removeClass('tab-focus is-open');
    $(e.target).closest('li.site-nav-primary-item').addClass('tab-focus');
    primaryNav.addClass('focus');
  });
  /**
   * Hide secondary-nav when leaving #primary-nav menu item
   */

  $(navSelector).on('focusout mouseleave', function (e) {
    var navItem = $(e.target).closest('li');
    if (menuElemOpen !== null && menuElemOpen.get(0) !== navItem.get(0)) {
      focusOutElem(navItem, menuElemOpen, true);
    }
  });
  primaryNav.on('keydown', function (e) {
    var screenSize = ONNIT.nav.state,
      currentNavItem = $('li.menu-item.site-nav-primary-item' + '.' + tabFocusClass),
      hasSecondary = currentNavItem.hasClass('has-secondary'),
      openNavItem = $(e.target).closest('li.site-nav-primary-item').hasClass('toggled-on');
    switch (e.keyCode) {
      case 13:
        if (hasSecondary && !currentNavItem.hasClass('is-open')) {
          e.preventDefault();
        }
        if (screenSize === 'smallscreen') {
          if ($(e.target).hasClass('close-mobile-subnav')) {
            ONNIT.nav.sub_toggle(e);
          }
          return;
        }
        var navItem = $(e.target).closest('li');
        focusInElem(navItem, currentNavItem);
        break;
      case 27:
        // Escape
        e.preventDefault();
        if (screenSize !== 'bigscreen' && openNavItem.length > 0) {
          e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        prevEvent = 27;
        currentNavItem.removeClass(openClass).children('.site-nav-primary-anchor').attr('aria-expanded', "false").focus();
        prevEvent = null;
        break;
      case 40:
        // Down Arrow
        if (screenSize !== 'bigscreen' && currentNavItem.find('.subnav').is(':visible')) {
          return;
        }
        break;
      case 39:
        e.preventDefault(); // Right Arrow

        if (!currentNavItem.is(":nth-last-child(2)")) {
          primaryNav.trigger('focusRemoved');
          currentNavItem.next().children('a.site-nav-primary-anchor').focus();
        }
        break;
      case 35:
        // End Button
        e.preventDefault();
        currentNavItem.find('.subnav .site-nav-secondary-item:last a').focus();
        break;
      case 36:
        // Home Button
        e.preventDefault(); // Focus on first li in subnav

        currentNavItem.find('.subnav .site-nav-secondary-item:visible:first a').addClass('focused-item').focus();
        break;
      case 38:
        // Up Arrow
        if (screenSize !== 'bigscreen' && currentNavItem.find('.subnav').is(':visible')) {
          return;
        }
        break;
      case 37:
        // Left Arrow
        e.preventDefault();
        if (!currentNavItem.is(":first-child")) {
          currentNavItem.prev().children('a.site-nav-primary-anchor').focus();
        }
        break;
      default:
        break;
    }
  });
  function focusInElem(elem, elemOpen) {
    var screenSize = ONNIT.nav.state;
    if (elem.length > 0 || elem.get(0) !== elemOpen.get(0)) {
      elemOpen.removeClass(tabFocusClass + ' ' + openClass).trigger('mouseleave');
    }
    elem.addClass(tabFocusClass + ' ' + openClass).trigger('mouseenter');
    elem.find('a.site-nav-primary-anchor').attr('aria-expanded', true);
  }
  function focusOutElem(elem, elemOpen, force) {
    force = force || false; // Get the focusedOut li

    if (force || elem.length > 0 && elem.get(0) !== elemOpen.get(0)) {
      elemOpen.removeClass(tabFocusClass + ' ' + openClass).trigger('mouseleave');
      elem.find('.site-nav-primary-anchor').attr('aria-expanded', false);
      elemOpen.find('a.site-nav-primary-anchor').attr('aria-expanded', false);
      return true;
    }
    return false;
  }
});